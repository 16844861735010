import styled from '@emotion/styled';
import { NavLink } from '@mantine/core';
import { memo } from 'react';
import { AiFillStar, AiOutlineHome } from 'react-icons/ai';
import { BsBarChartFill } from 'react-icons/bs';
import { AppLink2 } from '~/components/AppLink2';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const BoleStock_SidePane1 = memo(function BoleStock_SidePane1() {
    const currentURL = window.location.href;
    return (<styleds.container>
      <AppLink2 href={'/bole_stock'}>
        <NavLink active={currentURL.includes('/bole_stock') || currentURL === 'https://bole-stock.moneycom.in/'} description={'威力指標看盤'} label='伯樂看盤' color='gray' variant='filled' icon={<AiOutlineHome />}/>
      </AppLink2>
      <AppLink2 href={'/high-weight-stock'}>
        <NavLink active={currentURL.includes('/high-weight-stock')} description={'前二十大權值股報價'} label='權值股' color='gray' variant='filled' icon={<BsBarChartFill />}/>
      </AppLink2>
      <AppLink2 href={'/user-stock'}>
        <NavLink active={currentURL.includes('/user-stock')} description={'用戶自選股報價'} label='自選股' color='gray' variant='filled' icon={<AiFillStar />}/>
      </AppLink2>
      <AppLink2 href={'/'}></AppLink2>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #333333;
    border-radius: 4px;
    gap: 4px;
    padding: 4px;
    & > * {
      width: 100%;
    }
  `,
    pageItem: styled.div ``,
};
