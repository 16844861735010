import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import WatchListAddButton from '~/modules/symbolPlatform/WatchListAddButton';
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
import { store } from '~/pages/heineken_template/_private/store';
export const SymbolLink = memo(function SymbolLink() {
    const symbol = useSnapshot(store).charting.symbol;
    const symbolName = fr_instrument.getName(symbol);
    /** 加權頁面 */
    const tseaLink = 'https://tw.stock.yahoo.com/quote/%5ETWII';
    /** 櫃買頁面 */
    const otcLink = 'https://tw.stock.yahoo.com/s/otc.php';
    /** 台指頁面 */
    const txfLink = 'https://tw.stock.yahoo.com/future/charts.html?sid=WTX%26&sname=%E5%8F%B0%E6%8C%87%E6%9C%9F%E8%BF%91%E4%B8%80&mid=01&type=1';
    /** 個股基本面 */
    const stockLink = 'https://tw.stock.yahoo.com/quote/' + symbol + '/profile';
    const basicLink = () => {
        if (symbol.includes('TSEA')) {
            return tseaLink;
        }
        else if (symbol.includes('OTCA')) {
            return otcLink;
        }
        else if (symbol.includes('TX')) {
            return txfLink;
        }
        else
            return stockLink;
    };
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList('bole_stock_group_1');
    return (<styleds.container>
      <styleds.linkContent>
        <styleds.yahooIcon>yahoo!股市</styleds.yahooIcon>
        <styleds.link href={basicLink()} target='_blank' rel='noreferrer'>
          <span>{symbolName}資料</span>
        </styleds.link>
      </styleds.linkContent>
      <styleds.buttonContent>
        <WatchListAddButton.Display watchListSymbol={watchListState.symbolListArray} watchlistActs={watchListActs}/>
      </styleds.buttonContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    background-color: #272727;
    border-radius: 4px;
    padding: 0 2px;
  `,
    linkContent: styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
    link: styled.a `
    padding: 0 2px;
    font-size: 14px;
    color: #3bc1ff;
  `,
    yahooIcon: styled.div `
    background-color: #7427f5;
    color: #ffffff;
    border-radius: 2px;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: bold;
  `,
    buttonContent: styled.div `
    width: 104px;
    height: 32px;
  `,
};
