import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { store } from '~/pages/heineken_template/_private/store';
export const StockScreener30minute = memo(function StockScreener30minute() {
    const kdSymbol = useVirtualAccountHolding('bole_stock')?.filter(datum => datum.qty === 1);
    return (<styleds.container>
      <SimpleQuoteListInteractWithChart data={kdSymbol?.map(position => position.symbol) ?? ['']} chart={store.charting} handleClick={() => store.charting.changeInterval(30)}/>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
};
