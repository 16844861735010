import { store } from '~/pages/heineken_template/_private/store';
import { bole_deduction_dark } from '~/trades/indicators/bole_stock/bole_deduction';
import { bole_kd_dark } from '~/trades/indicators/bole_stock/bole_kd_dark';
import { bole_ma_dark } from '~/trades/indicators/bole_stock/bole_ma_dark';
import { bole_rsi_dark } from '~/trades/indicators/bole_stock/bole_rsi_dark';
import { bole_volume_dark } from '~/trades/indicators/bole_stock/bole_volume_dark';
// const preparedIndicators = [bole_volume, bole_kd, bole_rsi, bole_ma, bole_deduction]
export const bole_indicators = [
    bole_volume_dark,
    bole_kd_dark,
    bole_rsi_dark,
    bole_ma_dark,
    bole_deduction_dark,
];
export const bole_strategiesGroup = {
    main: [
        {
            displayName: '策略',
            indicators: bole_indicators,
            symbol: 'TSEA',
            interval: 'D',
            panesRatio: 60,
        },
    ],
};
export const boleStock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...bole_indicators];
    store.charting.initStrategy({
        configs: [...bole_strategiesGroup.main],
    });
};
