import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { BoleStock_Topbar } from './boleStock_Topbar';
import { BoleStock_SidePane2 } from './boleStock_SidePane2';
import { boleStock_initStrategies } from './boleStock_initStrategies';
import { boloeStock_initStyling } from './boleStock_initStyling';
import { BoleStock_SidePane1 } from './boleStock_SidePane1';
import { HighWeightSymbolList } from '../high-weight-stock/HighWeightSymbolList';
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
import { WatchListSymbolList } from '../user-stock/WatchListSymbolList';
export const boleStock_init = {
    global(templateProps) {
        boleStock_initStrategies();
        boloeStock_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = BoleStock_Topbar;
    },
    indexPage(templateProps) {
        boleStock_initStrategies();
        boloeStock_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/bole_stock/loginView.jpeg'] }]}/>);
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TSEA',
            interval: 'D',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 336px 1fr 336px;
      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Chart Drawer2'
        'Drawer1 Chart Drawer2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}
    `;
        templateProps.layout.Row1 = BoleStock_Topbar;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.layout.Drawer2 = BoleStock_SidePane2;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['high-weight-stock/index.page'](templateProps) {
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = BoleStock_Topbar;
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
            enableVolumeIndicator: true,
            disabledHeaderWidget: false,
            disabledLeftToolbar: true,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            disabledSymbolSearch: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 336px 1fr 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}
    `;
        templateProps.layout.Row1 = BoleStock_Topbar;
        templateProps.layout.Col1 = HighWeightSymbolList;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        //templateProps.layout.Drawer2 = BoleStock_SidePane2
        templateProps.hooks.add(useSignalrStart2_0);
        templateProps.hooks.add(fr_instrument.installData);
    },
    ['user-stock/index.page'](templateProps) {
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = BoleStock_Topbar;
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
            enableVolumeIndicator: true,
            disabledHeaderWidget: false,
            disabledLeftToolbar: true,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            disabledSymbolSearch: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 336px 1fr 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}
    `;
        templateProps.layout.Row1 = BoleStock_Topbar;
        templateProps.layout.Col1 = WatchListSymbolList;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.hooks.add(useSignalrStart2_0);
        templateProps.hooks.add(fr_instrument.installData);
    },
};
