import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Progress } from '@mantine/core';
import { memo } from 'react';
import { fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
export const StockChangeBar = memo(function StockChangeBar(props) {
    const value = props.value;
    const totalChange = value.reduce((accumulator, currentValue) => {
        const changes = processQuoteToReadable(currentValue);
        return accumulator + changes.closeChangePercent;
    }, 0);
    const positiveChanges = value.filter(quote => {
        const changes = processQuoteToReadable(quote);
        return changes.closeChange > 0;
    });
    const negativeChanges = value.filter(quote => {
        const changes = processQuoteToReadable(quote);
        return changes.closeChange < 0;
    });
    const negativeLength = negativeChanges.length;
    const positiveLength = positiveChanges.length;
    const flatLength = value.length - negativeLength - positiveLength;
    const positive = (negativeLength / value.length) * 100;
    const negative = (positiveLength / value.length) * 100;
    const flat = (flatLength / value.length) * 100;
    //const avgChange = totalChange / value.length
    return (<styleds.container>
      <styleds.titleContent>
        <div>自選股漲跌比例</div>
        <span css={css `
            color: #22cc22;
          `}>
          下跌{negativeLength}
        </span>
        <span css={css `
            color: #fafafa;
          `}>
          持平{flatLength}
        </span>
        <span css={css `
            color: #ee0000;
          `}>
          上漲{positiveLength}
        </span>
      </styleds.titleContent>
      <styleds.barContent>
        <Progress css={css `
            &.mantine-aihjca {
              margin-top: 0;
            }
          `} mt='md' size={16} sections={[
            {
                value: negative,
                color: '#00aa00',
                label: negative.toFixed(0) + '%',
                tooltip: '下跌家數：' + negativeLength,
            },
            {
                value: flat,
                color: '#cacaca',
                label: flat.toFixed(0) + '%',
                tooltip: '持平家數：' + flatLength,
            },
            {
                value: positive,
                color: '#cc0000',
                label: positive.toFixed(0) + '%',
                tooltip: '上漲家數：' + positiveLength,
            },
        ]}/>
      </styleds.barContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 100%;
  `,
    titleContent: styled.div `
    width: 100%;
    display: grid;
    grid-template-columns: 40% 19% 19% 19%;
    height: 24px;
    font-size: 14px;
    gap: 1%;
    & > span {
      ${fill_vertical_all_center};
      height: 20px;
      background-color: #363636;
      border-radius: 6px;
    }
  `,
    barContent: styled.div `
    width: 100%;
    height: calc(100% - 24px);
  `,
};
