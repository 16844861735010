import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { ThemeToggle } from '~/components/theme/ThemeToggle';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
import { store } from '~/pages/heineken_template/_private/store';
const SymbolButton = memo(function SymbolButton(props) {
    const selected = props.symbol === useSnapshot(store).charting.symbol;
    const symbolName = fr_instrument.getName(props.symbol);
    return (<Button css={css `
        width: 132px;
        height: 32px;
      `} onClick={() => store.charting.changeSymbol(props.symbol)} variant={selected ? 'filled' : 'default'}>
      {props.symbol}
      {symbolName}
    </Button>);
});
export const BoleStock_Topbar = memo(function BoleStock_Topbar() {
    return (<styleds.container>
      <styleds.topbarContent>
        <SymbolButton symbol='TSEA'/>
        <SymbolButton symbol='OTCA'/>
        <SymbolButton symbol='TX-1'/>
        <SymbolButton symbol='TXAM-1'/>
      </styleds.topbarContent>
      <styleds.topbarContent>
        <ChartingServerSwitch charting={store.charting}/>
        <ThemeToggle onClick={event => {
            store.charting.setThemeMode(useThemeStore.getState().theme);
            store.charting.updateFromThemeMode();
        }}/>
        <UserAvatarAsDialogButton />
      </styleds.topbarContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    padding: 0px 4px;
    background-color: #222222;
  `,
    topbarContent: styled.div `
    ${fill_horizontal_cross_center};
    width: auto;
    gap: 6px;
  `,
};
